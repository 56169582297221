import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import { plugin } from 'echarts-for-vue';
// import * as echarts from 'echarts';
//进度条
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'


Vue.use(ElementUI);
// Vue.use(plugin, { echarts });   
Vue.config.productionTip = false

// //进度条
// NProgress.configure({
//   easing: 'ease',  // 动画方式
//   speed: 500,  // 递增进度条的速度
//   showSpinner: false, // 是否显示加载ico
//   trickleSpeed: 200, // 自动递增间隔
//   minimum: 0.3 // 初始化时的最小百分比
// })

// router.beforeEach((to, from , next) => {
//   // 每次切换页面时，调用进度条
//   NProgress.start();
//   next();
// })
// router.afterEach(() => {
//   // 在即将进入新的页面组件前，关闭掉进度条
//   NProgress.done()
// })




new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
